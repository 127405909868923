#summary-checker {
    
    .align-items-vertical {
        display: grid;
        justify-content: center;
        align-items: stretch;
    }

    .card-title {
        vertical-align: top;
        min-height: 0;
    }

    .start-date-input {
        margin-right: 1rem;
    }

    .MuiCard-root {

        .card-image {
            width: 50%;
            height: auto;
        }

    }

}