@use '../../../scss/colors' as *;

// for table results total text 
.results-found-text {
    font-size: 50%;
    float: right;
    padding-left: 0.5rem;

    @media only screen and (min-width: 300px) and (max-width: 600px) {
        float: none;
        display: inline-block;
        &::before {
            content: "(";
        }
        &::after {
            content: ")";
        }        
    }
}

.results-found-text-alert {
    font-size: 50%;
    float: right;
    color: $red-dark;
    font-weight: 500;
    padding-left: 0.5rem;
}