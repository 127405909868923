$bezos-blue: rgb(49, 74, 131);
$bezos-blue-light: rgb(25, 118, 210);
$bezos-blue-medium: rgb(20, 105, 190);
$bezos-blue-dark: rgb(15, 95, 170);

$bezos-green: rgb(60,184,121);
$bezos-green-alpha-0: rgba(60, 184, 121, 0.04);
$bezos-green-alpha-1: rgba(60,184,121,0.1);
$bezos-green-alpha-2: rgba(60,184,121,0.5);

$red: rgb(147, 0, 0);
$red-dark: rgb(161, 51, 51);
$alert: rgb(211,47,47);

$white-dark: rgba(224, 224, 224, 1);
$white-medium: rgb(240,240,240);
$white-light: rgb(245,245,245);
$white: rgb(255,255,255);

$black: rgb(0,0,0);
$black-medium: rgb(0,0,0, 0.6);

$transparent: rgba(0, 0, 0, 0);