@use './variables' as *;
@use './colors' as *;

table {

  th {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    font-weight: bold !important;
  }

  td {
    padding: 0.5rem, 0 !important;
  }

  .table-header {
    font-weight: bold;
  }

  // ensures tracking coloumn displays correctly
  .t-status {
    max-width: 100px;
    margin-right: 0.25rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .table-error-message-text {
      text-align: center !important;
      color: $red-dark !important;
      font-weight: $font-weight-light !important;
      line-height: 2rem;
  }

}

.table-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  @media only screen and (max-width: 700px) {
    justify-content: start;
  }

}