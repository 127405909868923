@use '../../scss/mixin' as *;
@use '../../scss/colors' as *;

#status-checker {

    @include tableStyleMain();

    .frozen-status {
        color: $bezos-blue-light;
        font-style: italic;
        font-size: 90%;
        margin-left: 0.25rem;
        word-break: keep-all;
    }
}