@use '../../scss/colors' as *;
@use '../../scss/variables' as *;

#debug {

    .debug-token {
        border-radius: $border-radius-small;
        padding: 0.5rem 1rem;
        border: 2px solid $white-medium;
        overflow-wrap: anywhere;
        font-size: $font-size-medium;
        font-weight: $font-weight-none !important;

        &:hover {
            border: 2px solid $bezos-green;
            cursor: pointer;
            animation: fadeInHover 0.75s forwards;
        }
        @keyframes fadeInHover{
            0%{
                border: 2px solid $white-medium;
            }
            100%{
                border: 2px solid $bezos-green;
            }
        }

        &:active {
            background-color: $bezos-green;
            color: $white;
            cursor: pointer;
            animation: fadeInActive 0.75s forwards;
        }
        @keyframes fadeInActive{
            0%{
                background-color: $white-medium;
                color: inherit;
            }
            100%{
                background-color: $bezos-green;
                color: $white;
            }
        }

        .Debug {
            max-width: 80%;
            overflow-wrap: break-word;
        }        
    }
    
}