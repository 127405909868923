$font-default: Raleway, sans-serif, Arial, Helvetica, sans-serif;
$font-alt1: "Roboto","Helvetica","Arial",sans-serif;

$font-size-base: 14px;
$font-size-extra-small: 0.75rem;
$font-size-small: 0.875rem;
$font-size-medium: 1rem;

$font-weight-base: 500;
$font-weight-none: 000;
$font-weight-very-light: 300;
$font-weight-light: 500;
$font-weight-medium: 600;
$font-weight-heavy: 900;

$font-line-height-small: 1.66;
$font-line-height-alt1: 1.75;

$font-letter-spacing-alt1: 0.02857em;
$font-letter-spacing-alt2: 0.03333em;

$border-radius-small: 0.5rem;
