@use './colors' as *;
@use './variables' as *;

@mixin tableStyleMain() {
    .start-date-input {
        margin-right: 1rem;
    }

    table {
        .MuiTableRow-head {
            vertical-align: bottom;
        }

    }
}

@mixin style-upload-button() {
    input[type=file]::file-selector-button {

        font-family: $font-alt1;
        font-weight: $font-weight-base;
        font-size: $font-size-small;
        line-height: $font-line-height-alt1;
        letter-spacing: $font-letter-spacing-alt1;
        text-transform: uppercase;
        min-width: 64px;
        padding: 5px 15px;
        border-radius: 4px;

        border: 1px solid $bezos-green-alpha-2;
        border-bottom: 1px solid $white-dark;

        color: $bezos-green;
        background-color: $white;
        box-shadow: none;

        text-indent: 0px;
        text-shadow: none;
        text-align: center;

        &:hover {
            text-decoration: none;
            background-color: $bezos-green-alpha-0;
            border: 1px solid $bezos-green;
        }
    }

}

// used for status and send email button
@mixin buttonStyle() {
    border-radius: 5px;
    display: inline-block;
    word-break: keep-all;
    width: fit-content;
    text-align: center;
    font-size: 0.75rem;
    padding: 0.5rem;
}

@mixin removeTextDecoration() {
    a {
        text-decoration: none;
    }
}

@mixin toggle-visibility() {

    @media only screen and (max-width: 600px) {
        .display-min-width {
            display: inline-block;
        }
        .display-max-width {
            display: none;
        }
      }
      
      @media only screen and (min-width: 601px) {
        .display-min-width {
            display: none;
        }        
        .display-max-width {
            display: inline-block;
        }
      }

}