@use '../../scss/mixin' as *;
@use '../../scss/variables' as *;

#despatch {

    .dispatch-ticket-button {
        padding-left: 0.5rem;
        display: inline-block;
    }
    
    ul.collection-status-list {
        list-style-type: none;
        margin: 1rem 0 0 1rem;
        padding: 0;
    }
    
    .collection-status-list li {
        margin: 0 0 1rem 0;
    }

}
