@use './variables' as *;
@use './colors' as *;
@use './tables' as *;
@use './mixin' as *;

body {
    min-height: 100vh;
    margin: 0;
    font-family: $font-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
    font-size: $font-size-base;
    font-weight: $font-weight-base;
}

// MainPanel
.invalid-page-alert {
    text-decoration: underline;
    font-weight: $font-weight-heavy;
}

.checkbox-field {
    margin-right: 0.5rem;
}

/* input fields above table data */
.form-inputs {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    @media only screen and (min-width: 300px) and (max-width: 1250px){
        justify-content: flex-start;
        align-items: flex-start;
        align-self: start;
        align-content: flex-start;
    }
}

.alert-message {
    text-transform: capitalize;
    display: inline-block;
}

.email-button {
    @include buttonStyle();
}

.status-button {
    @include buttonStyle();
}

// spacing between back button and continue
.back-button {
    margin-right: 1rem;
}

.align-content-vertical {
    display: flex;
    align-items: center;
}

.align-content-right {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 1rem;
}

// Sign In
.amplify-button--primary {
    background-color: $bezos-blue-light;
    &:hover {
        background-color: $bezos-blue-medium;
    }
    &:active {
        background-color: $bezos-blue-dark
    }
}

.download-csv-button {
    @include removeTextDecoration();

    text-align: right;
    margin-top: 1rem;
    @media only screen and (max-width: 1000px){
        text-align: left;
    }
}

  // hide/show form panels based on completion
  .panel-hide {
    display: none;
    visibility: hidden;
  }
  .panel-show {
    display: block;
    visibility: visible;
    padding-top: 1%;
  }
  .panel-active {
    width: 100%;
  }
  .panel-disabled {
    width: 100%;
  }
