@use "../../../scss/mixin" as *;

.data-table {

    table {

        td {
            word-break: keep-all;
        }

    }

}