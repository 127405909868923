@use '../../../scss/colors' as *;

#side-nav {

    .side-nav-header {
        display: flex;
        justify-content: space-between;
    }

    .MuiList-root {
        padding: 0;
        margin: 0;
    }

    .MuiListItem-root {
        .page-match {
            background-color: $bezos-green;
        }
    }

}

